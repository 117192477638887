<template>
    <div class="">
        <div class="hloga" @click="goto('/',null)" ><!--<img :src="hloga">--></div>
        <section>
            <div class="shzzg1">
                <div style="font-size: 60px;font-weight: bold;padding-top: 300px;color:#ffffff;">
                    服务全球  保护地球
                </div>
                <div style="font-size: 18px; color:rgba(255,255,255,0.8);margin-top: 30px;line-height: 32px;">
                    辉腾能源立足绿色发展，秉承绿色环保、专业优质理念，专注低日照弱光环境下太阳能技术研发、产品制造与应用，<br/>
                    提供满足市场需求的智慧绿色节能产品，<br/>
                    开发出太阳能家用发电管理系统、太阳能路灯智慧管理系统，<br/>
                    服务人与自然和谐共生，助力实现碳达峰、碳中和！
                </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;color: #202020" >
                    <div><span class="btLine">环境</span>责任</div>
                </div>
                <div class="hcell" style="width: 1400px;" >
                    <div  v-for="(n,i)  of Math.ceil((envinews.length)/2)  " :key="'environment'+envinews[2*i].tb_id">
                        <div class="htable" style="width: 100%;margin-bottom: 40px;">
                            <div class="hcell" style="width: 420px;font-size: 24px;font-weight: bold;color: #202020;" >
                                {{envinews[2*i].tb_title}}
                            </div>
                            <div class="hcell" style="width: 980px;height: 312px;background-color: #f3f3f3" >
                                <div class="htable"  style="align-items:center;width: 980px;">
                                    <div class="hcell" style="width:440px;height:312px;overflow: hidden;">
                                        <img style="width: 400px;margin-top: 20px;margin-left: 10px;" :src="envinews[2*i].tb_image">
                                    </div>
                                    <div class="hcell" style="width:540px;text-align: left;font-size: 18px;line-height: 32px;color: rgba(32,32,32,0.8);" >
                                        <div style="padding: 0 50px;">
                                       {{envinews[2*i].tb_summary}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="htable" style="width: 100%;margin-bottom: 40px;" v-if="envinews[2*i+1]">
                            <div class="hcell" style="width: 420px;font-size: 24px;font-weight: bold;color: #202020;" >
                                {{envinews[2*i].tb_title}}
                            </div>
                            <div class="hcell" style="width: 980px;height: 312px;background-color: #f3f3f3" >
                                <div class="htable"  style="align-items:center;width: 980px;">

                                    <div class="hcell" style="width:540px;text-align: left;font-size: 18px;line-height: 32px;color: rgba(32,32,32,0.8);" >
                                        <div style="padding: 0 50px;">
                                            {{envinews[2*i].tb_summary}}
                                        </div>
                                    </div>
                                    <div class="hcell" style="width:440px;height:312px;overflow: hidden;">
                                        <img style="width: 400px;margin-top: 20px;margin-left: 10px;" :src="envinews[2*i].tb_image">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="hcell" style="width: 130px;" ></div>
            </div>
        </section>
        <section style="margin-top: 50px;">
            <div class="shzzg2">
                <div class="body1400">
                    <div style="font-size: 36px;font-weight: bold;color: rgba(255,255,255,0.9);padding-top: 120px;" >获得成就</div>
                    <div style="font-size: 18px;line-height: 18px;color: rgba(255,255,255,0.9);margin-top: 30px;" v-html="frameBase[0].fb_content">

                    </div>
                    <div class="htable" style="">
                        <div class="hcell jnjptdiv" v-for="(n,i)  of environment.length " :key="'item'+environment[i].tb_id" >
                            <div style="text-align: center;width: 100%;">
                                <div><img :src="environment[i].tb_image"></div>
                                <div><span class="jnjpnum" > {{environment[i].tb_summary}}</span><span class="jnjpunit"> {{environment[i]._sub}}</span></div>
                                <div class="jnjptype"> {{environment[i].tb_title}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1400">
                <div style="font-size: 36px;font-weight: bold;color: #202020;">
                    绿色办公楼
                </div>
                <div style="font-size: 18px;line-height: 32px;color: rgba(32,32,32,0.8);margin: 40px 0;">
                    重庆市第一个工业园区屋顶分布式光伏发电站，由辉腾能源投资建设,装机容量354kW，<br/>
                    年发电量约30万度，年节约标准煤约116吨，降低79吨粉尘、289吨二氧化碳、8.7吨二氧化硫。
                </div>
                <div>
                <img :src="eldz">
                </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px; ">
                    <div><span class="btLine">社会</span>责任</div>
                </div>
                <div class="hcell " >
                    <div class="htable" style="width: 1530px;align-items: center;justify-content: space-between;">
                    <div class="hcell shzrnewdiv" v-if="rows[0]">
                        <div class="newsimg">
                            <img class="mimg" :src="rows[0].tb_image"  :title="rows[0].tb_title">
                        </div>
                        <div class="newtitle" :title="rows[0].tb_title"><a class="mgd" @click="goto('/new',{id: rows[0].tb_id})" >{{rows[0].tb_title}}</a></div>
                        <div class="newdate">{{rows[0].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="hcell shzrnewdiv" v-else ></div>
                    <div class="hcell shzrnewdiv" v-if="rows[1]">
                        <div class="newsimg">
                            <img class="mimg" :src="rows[1].tb_image"  :title="rows[1].tb_title">
                        </div>
                        <div class="newtitle" :title="rows[1].tb_title"><a class="mgd" @click="goto('/new',{id: rows[1].tb_id})" >{{rows[1].tb_title}}</a></div>
                        <div class="newdate">{{rows[1].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="hcell shzrnewdiv" v-else ></div>
                    <div class="hcell shzrnewdiv" v-if="rows[2]">
                        <div class="newsimg">
                            <img class="mimg" :src="rows[2].tb_image"  :title="rows[2].tb_title">
                        </div>
                        <div class="newtitle" :title="rows[2].tb_title"><a class="mgd" @click="goto('/new',{id: rows[2].tb_id})" >{{rows[2].tb_title}}</a></div>
                        <div class="newdate">{{rows[2].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="hcell shzrnewdiv" v-else ></div>
                    </div>
                    <pages :page="param.page" :size="param.rows" :total="total" @go="goPage" style="margin-top: 40px;"></pages>

                </div>

            </div>
        </section>
        <section style="margin-top: 80px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBase,listTopicBases,frameBase } from "../hts/api.js"
    import pages from '../components/pageslr.vue'

    export default {
        name: "",
        components: {
            myhead,
            pages,
        },
        data() {
            return {
                param: {
                    pageindex: 2,
                    rows: 3,
                    page: 1,
                },
                jnzs: require('../assets/jnzs.png'),
                shzz_zj: require('../assets/shzz_zj.png'),
                shzz_tan: require('../assets/shzz_tan.png'),
                shzz_co2: require('../assets/shzz_co2.png'),
                shzz_tree: require('../assets/shzz_tree.png'),
                eldz: require('../assets/eldz.png'),
                rows:[],
                total:0,
                environment:[],
                envinews:[],
                frameBase:[],
            }
        },
        mounted() {
            this.getsocialResponsibilitys()
            this.getList()
            this.getFrameBase()
        },
        methods: {
            goto(url,query) {
                this.$router.push({ path: url, query: query });
            },
            goPage (page) {
                this.param.page = page
                this.getsocialResponsibilitys()
            },
            getsocialResponsibilitys () {
                // console.log(this.id)
                listTopicBase({type: 'socialResponsibility', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.rows=resData.data.rows
                        this.total=resData.data.total
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },

            getList () {
                // console.log(this.id)
                listTopicBases({type: 'newWebsite:[environment,envinews]', page: 1, rows: 4 })
                    .then(resData => {
                        this.environment=resData.data.environment
                        this.envinews=resData.data.envinews
                    })
                    .catch()
            },
            getFrameBase () {
                // console.log(this.id)
                frameBase({id: 'environment'})
                    .then(resData => {
                        this.frameBase=resData.data.rows
                    })
                    .catch()
            },
        },
    }
</script>
<style lang="css" scoped>
    .hloga{
        position: absolute;
        z-index: 11111;
        background-image: url('~assets/hloga.png');
        width: 230px;
        height: 30px;
        top: 65px;
        left: 130px;
    }
    .hloga:hover {
        cursor: pointer;
        background-color: rgba(255,255,255,0.5);
    }
    .shzzg1{
        background-image: url('~assets/shzzg1.png');
        height: 730px;
    }
    .shzzg2{
        background-image: url('~assets/shzzg2.png');
        height: 700px;
    }

    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;
    }
    .jnjptdiv{
        border-right:1px solid rgba(255,255,255,0.8);
        height: 260px;
        width: 350px;
        display: flex;
        align-items:flex-end;
        margin-top: 60px;
    }
    .jnjpnum{
        font-size: 28px;
        font-weight: bold;
        color: #ffffff;
    }
    .jnjpunit{
        font-size: 20px;
        color: #ffffff;
    }
    .jnjptype{
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
        padding-bottom: 40px;
    }
    .shzrnewdiv{
        height: 470px;
        width: 490px;
        background-color: #f3f3f3 ;
    }

    .newsimg{
        height: 320px;
        background-color:rgba(230,230,230,0.5);
        overflow: hidden;
    }
    .mimg{
        width: 490px;
    }
    .newtitle{
        /*height: 25px;
        overflow: hidden;*/
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 32px;
    }
    .newdate{
        font-size: 16px;
        color: rgba(0,0,0,0.6);
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 20px;
    }

    .mgd {
        cursor:pointer;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        border-radius: 5px;
    }
</style>
