import { render, staticRenderFns } from "./shzz.vue?vue&type=template&id=65c6ff33&scoped=true&"
import script from "./shzz.vue?vue&type=script&lang=js&"
export * from "./shzz.vue?vue&type=script&lang=js&"
import style0 from "./shzz.vue?vue&type=style&index=0&id=65c6ff33&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c6ff33",
  null
  
)

export default component.exports