<template>
    <div class="pagination" v-if="total" @mouseenter="showJump=true" @mouseleave="showJump=false">
        <div class="pagitem" >
  <!--          <div  style="width: 50px;" title="首页" v-if="!nums.includes(1)" @click="go(1)">
                1
            </div>
-->
            <div class="pagcell leftb"   title="上一页" v-if="now > 1" @click="go(now-1)"></div>
            <div class="pagcell lefth"  title="上一页" v-else>

            </div>
            <div class="pagcell"  ></div>
            <!--
                        <div class="pagcell " style="width: 50px;" :class="{active:num===now}" v-for="num of nums" @click="go(num)" :key="num"  >
                            <i class="hlink">{{num}}</i>
                        </div>
            -->

            <div class="pagcell rightb"   title="下一页" v-if="now < total_page" @click="go(now+1)">
            </div>
            <div class="pagcell righth"   title="下一页" v-else>
            </div>
            <!--
                        <div class="pagcell" style="width: 50px;" title="末页" v-if="!nums.includes(total_page)" @click="go(total_page)">
                            {{total_page}}
                        </div>

                        <span style="width: 80px;line-height: 50px;">共 {{total}} 条</span>

                        <div class="pagcell" v-if="showJump && total_page>ext*2+2">
                            <div class="ui transparent icon input">
                                <input type="text" placeholder="页数" v-model="jump" @keyup.enter="jumpTo" style="width: 70px;">
                                <i class="arrow right link icon" title="转到此页" @click="jumpTo"></i>
                            </div>
                        </div>-->
        </div>
    </div>
    <div class="ui yellow message" v-else>暂无数据</div>
</template>

<script>
    export default {
        props: {
            ext: {
                type: Number,
                default: 3,
            },
            size: {
                type: Number,
                default: 20,
            },
            total: {
                type: Number,
                required: true,
            },
            page: {
                type: [Number, String],
                default: 1,
            },
        },
        data () {
            return {
                showJump: false,
                jump: '',

                arlefcib: require('../assets/arlefcib.png'),
                arlefcih: require('../assets/arlefcih.png'),
                arrigcib: require('../assets/arrigcib.png'),
                arrigcih: require('../assets/arrigcih.png'),
            }
        },
        computed: {
            now () {
                return parseInt(this.page, 10) || 1
            },
            total_page () {
                return Math.ceil(this.total / this.size)
            },
            nums () {
                let nums = [this.now]
                //在页首和页尾，把不足ext的页数显示在相反的一面
                let after = this.now <= this.ext ? this.ext - this.now + 1 : 0
                let before =
                    this.total_page - this.now < this.ext
                        ? this.ext - (this.total_page - this.now)
                        : 0
                //当前页的前面
                for (
                    let i = this.now - 1;
                    this.now - i <= this.ext + before && i > 0;
                    i--
                ) {
                    nums.unshift(i)
                }
                //当前页的后面
                for (
                    let i = this.now + 1;
                    i - this.now <= this.ext + after && i <= this.total_page;
                    i++
                ) {
                    nums.push(i)
                }
                return nums
            },
        },
        methods: {
            go (num) {
                if (num !== this.now) {
                    this.$emit('go', num)
                }
            },
            jumpTo () {
                const num = parseInt(this.jump, 10)
                if (Number.isInteger(num) && num > 0 && num <= this.total_page) {
                    this.go(num)
                    this.jump = ''
                } else {
                    //NOTE('请输入 1 到 ' + this.total_page + ' 的页数', 'fail')
                }
            },
        },
        watch: {
            total_page: {
                handler (val) {
                    //当前页数比总页数大则重新获取最后一页
                    if (val && this.page > val) {
                        this.go(val)
                    }
                },
            },
        },
    }
</script>
<style lang="css" scoped>
    .pagination{
        /*border: 1px solid red;*/
        /*position:absolute;*/
        width: 100%;
        margin: 0px;
    }
    .pagitem{
        width: 100%;
        display: flex;
        justify-content: center;
        /*border: 1px solid green;*/
    }
    .pagcell{
        justify-content: flex-start;
        flex-direction: row;
        width: 40px;
        height: 40px;
        /*border: 1px solid red;*/
        /*background-color: #cccccc;*/
    }



    .leftb {
        background-image: url('~assets/arlefcib.png');
        cursor: pointer;
    }
    .leftb:hover {
        background-image: url('~assets/arlefcir.png');
        cursor: pointer;
    }
    .lefth {
        background-image: url('~assets/arlefcih.png');
    }
    .rightb {
        background-image: url('~assets/arrigcib.png');
    }
    .rightb:hover {
        background-image: url('~assets/arrigcir.png');
        cursor: pointer;
    }
    .righth {
        background-image: url('~assets/arrigcih.png');
    }


</style>
